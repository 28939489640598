import styled from "styled-components";

export const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;

  .main-canvas-class {
    height: 100%;
    width: 100%;
    border-radius: 10px;

    @media (max-width: 800px) {
      height: 100%;
      width: 100%;
    }

    @media (max-width: 800px) {
      height: 100%;
      width: 100%;
    }
  }
`;
