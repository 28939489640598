import LeaderBoard from "components/leaderBoard";
import React from "react";

const Leaderboard = () => {
  return (
    <div>
      <LeaderBoard />
    </div>
  );
};

export default Leaderboard;
