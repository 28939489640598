import AdminPanel from "components/admin";
import React from "react";

const Admin = () => {
  return (
    <div>
      <AdminPanel />
    </div>
  );
};

export default Admin;
